import {
	request
} from './base'


// 安卓提交邮箱
export const reserveEmail = (params, callback) => {
	request('/Api/User/ReserveEmail', 'post', params, callback)
}

