import axios from 'axios'
import qs from 'qs'
export const baseUrl = `${process.env.VUE_APP_HTTP}`
var option = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
  }
}

var ajax = axios.create(option)
ajax.interceptors.request.use(
  request => {
    return request
  },
  error => {
    return Promise.reject(error)
  }
)
ajax.interceptors.response.use(function(response) {
  // console.log(response)
  // 对响应数据做点什么
  return response
}, function(error) {
  // 对响应错误做点什么
  return Promise.reject(error)
})
export const request = (url, type, params, callback, callback2) => {
  const config = {
    url: baseUrl + url
  }

  if (type && type === 'post') {
    config.method = type
    config.data = qs.stringify(params)
  } else {
    config.params = params
  }
  // Indicator.open({
  //   text: '正在加载',
  //   spinnerType: 'fading-circle'
  // });
  ajax.request(config).then((res) => {
    // code  1001--成功  1005--微信授权登录
    switch (res.data.code) {
      case '000000':
        callback(res.data.data, res.data.message)
        break
      default:
        if (callback2) {
          callback2(res.data)
        } else {
          // Toast({
          //   message: res.data.msg,
          // })
        }
        config.params = params
    }
  }).catch((error) => {
    console.log(error)
    // Indicator.close();
    // Toast('系统错误，请稍候重试')
  })
}
