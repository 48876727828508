<template>
	<div class="Android-view">
		<div class="Android-content">
			<img src="../assets/img/logo2.png" alt="" class="logo" />
			<div class="title">Android APP will be released soon</div>
			<div class="tips">HeardLy will notify you by email<br>immediately after the launch，thanks</div>
			<div class="form">
				<input type="text" class="form-input" placeholder="Enter your Email" v-model="email"
					@input="emailInput">
				<div class="form-btn" @click="submit">Join the waiting list</div>
			</div>
			<div class="bottom-text">
				<img src="../assets/img/gift.png" alt="">
				<div>You will got <b>1 Month FREE PREMIUM</b>!</div>
			</div>
		</div>
		<footer-view></footer-view>
	</div>
</template>

<script>
	import * as api from '@/api'
	export default {
		data() {
			return {
				email: '',
				lock: false
			}
		},
		methods: {
			toIndex() {
				this.$router.push({
					path: '/'
				})
			},
			emailInput(e) {

			},
			submit() {
				if (!this.validateEmail(this.email)) {
					this.$toast.fail('email error')
					return false
				}
				if (this.lock) {
					return false
				} else {
					this.lock = true
					this.$toast.loading({
						message: 'loading',
						duration: 0,
						forbidClick: true,
					});

					api.reserveEmail({
						sign: this.$md5(this.email + '&Sending_SMS!@#$%^!'),
						email: this.email,
						device: navigator.userAgent
					}, res => {
						this.$toast.clear()
						this.email = ''
						this.lock = false
						this.$toast.success('success')
					})
				}

			},
			validateEmail(email) {
				let re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // 使用正则表达式进行邮箱格式校验
				return re.test(String(email).toLowerCase());
			}
		},
		mounted() {}
	}
</script>

<style lang="less" scoped>
	.Android-view {
		background: #FFFFFF;
		// padding-top: 87px;

		.Android-content {
			padding-top: 1.5rem;

			.logo {
				margin-left: 1.25rem;
				display: block;
				height: 48px;
				width: auto;
				margin-bottom: 4rem;
				cursor: pointer;
			}

			.title {
				// width: 25rem;
				padding-left: 2.625rem;
				padding-top: 3.333333rem;
				font-size: 2.5rem;
				font-weight: 600;
				color: #313B4F;
			}

			.tips {
				color: #858585;
				font-size: 1.25rem;
				padding: 1.458333rem 2.625rem;
			}

			.form {
				margin-top: 2.916667rem;

				.form-input {
					width: calc(100% - 2.5rem);
					border: 0.166667rem solid #19EBCC;
					border-radius: 0.333333rem;
					margin: 1.25rem;
					margin-bottom: 0.625rem;
					padding: 0 1.25rem;
					height: 4.041667rem;
					line-height: 4.041667rem;
					box-sizing: border-box;
					font-size: 1.5rem;
				}

				.form-input::placeholder {
					color: #ABABAB;
				}

				.form-btn {
					background: #19EBCC;
					font-size: 1.5rem;
					color: #07685A;
					margin: 1.25rem;
					margin-top: 0;
					width: calc(100% - 2.5rem);
					border-radius: 0.333333rem;
					height: 4.041667rem;
					line-height: 4.041667rem;
					text-align: center;
				}
			}

			.bottom-text {
				color: #E82207;
				font-size: 1.083333rem;
				text-align: center;
				margin-bottom: 15rem;
				line-height: 2.083333rem;

				img {
					width: 2.083333rem;
					height: 2.083333rem;
					vertical-align: middle;
					margin-right: 0.583333rem;
					margin-top: -0.166667rem;
				}

				div {
					display: inline-block;
				}
			}
		}
	}
</style>